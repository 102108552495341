<template>
  <vca-column>
    <h3>{{ $t("network.organizers.name.label") }}</h3>
    <div v-if="mode == 'edit'">
      <vca-input
        v-model.trim="value.name"
        ref="name"
        :rules="$v.value.name"
        :placeholder="$t('network.organizers.name.placeholder')"
        :errorMsg="$t('network.organizers.name.errorMsg')"
      />
      <div>
        <button class="vca-button" @click="add()">
          {{ $t("button.save") }}
        </button>
      </div>
    </div>
    <OrganizerDropdown v-else ref="name" v-model="value.name" @add="add()" />
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
import OrganizerDropdown from "@/components/network/organizers/OrganizerDropdown";
export default {
  name: "OrganizerForm",
  components: { OrganizerDropdown },
  data() {
    return {
      coe: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  computed: {
    ...mapGetters({
      validation: "organizers/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    add() {
      if (this.$v.$invalid) {
        this.$refs.name.validate();
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>
