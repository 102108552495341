<template>
  <vca-column>
    <vca-dropdown
      :taggable="true"
      v-model="organizer"
      :options="organizerList"
      :rules="$v.organizer"
      ref="organizer"
      label=""
      :placeholder="$t('network.organizers.name.placeholder')"
      :errorMsg="$t('network.organizers.name.errorMsg')"
    ></vca-dropdown>
    <button :disabled="!isUnique" class="vca-button" @click="add()">
      {{ $t("button.save") }}
    </button>
  </vca-column>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  name: "OrganizerDropdown",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      organizerList: "organizers/organizerList",
    }),
    isUnique() {
      return (
        this.value &&
        !this.organizerList.find(
          (el) => el.label.toLowerCase() == this.value.toLowerCase()
        )
      );
    },
    organizer: {
      get() {
        return this.value
          ? [
              {
                value: this.value,
                title: this.value,
                label: this.value,
              },
            ]
          : [];
      },
      set(value) {
        let doesExist;
        let emitValue;

        if (!value[0]) {
          emitValue = "";
        } else if (typeof value[0] === "string") {
          doesExist = this.organizerList.find(
            (el) => el.label.toLowerCase() == value[0].toLowerCase()
          );
          emitValue = value[0];
        } else {
          doesExist = this.organizerList.find(
            (el) => el.label.toLowerCase() == value[0].label.toLowerCase()
          );
          emitValue = value[0].label;
        }

        if (doesExist) {
          this.$emit("input", doesExist.label);
        } else {
          this.$emit("input", emitValue);
        }
        this.$refs.organizer.validate();
      },
    },
  },
  validations() {
    return {
      organizer: {
        required,
        isUnique(value) {
          const doesExist = this.organizerList.find(
            (el) => el.label.toLowerCase() == value[0].label.toLowerCase()
          );
          return doesExist == undefined;
        },
      },
    };
  },
  methods: {
    validate() {
      this.$refs.organizer.validate();
    },
    add() {
      this.$emit("add");
    },
  },
};
</script>
