<template>
  <vca-card class="shadowed">
    <OrganizerForm v-model="create" @submit="add" mode="create" />
  </vca-card>
</template>

<script>
import OrganizerForm from "@/components/network/organizers/OrganizerForm";
export default {
  name: "OrganizerAdd",
  components: { OrganizerForm },
  computed: {
    create: {
      get() {
        return this.$store.state.organizers.create;
      },
      set(value) {
        this.$store.commit("organizers/create", value);
      },
    },
  },
  methods: {
    add() {
      if (!confirm(this.$t("network.organizers.messages.add"))) {
        return false;
      }
      this.$store.dispatch({ type: "organizers/create" }).then(() => {
        this.create = { name: "" };
      });
    },
  },
};
</script>
