<template>
  <vca-card>
    <h2>{{ $t("network.organizers.add.header") }}</h2>
    <Add />
  </vca-card>
</template>

<script>
import Add from "@/components/network/organizers/OrganizerAdd";
export default {
  name: "NetworkOrganizerAdd",
  components: { Add },
  created() {
    this.$store.dispatch({ type: "organizers/list" });
  },
};
</script>
